import { createStore } from 'vuex';

export const store = createStore({
    state() {
        return {
            count: 0,
            isLoading: false, // 添加 isLoading 属性
        };
    },
    mutations: {
        increment(state) {
            state.count++;
        },
        setResData(state, data) { // 定义 setResData mutation
            state.resData = data;
        },
        setLoading(state, data){
            state.isLoading=data
        }
    },
});