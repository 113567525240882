<style>
.image-container {
  position: relative;
  z-index: 0;
}
</style>


<template>

  <div>
  <el-input v-model="params.username" clearable style="width: 200px" placeholder="请输入作者名" />
  <el-input v-model="params.title" clearable style="width: 200px" placeholder="请输入小说名"/>
  <el-button @click="findSearch()">查询</el-button>
  <el-button @click="showDialog=true">添加</el-button>
    <el-table :data="items" align="center">
      <el-table-column prop="username" label="作者名" width="110px" align="center"/>
      <el-table-column prop="title" label="小说名" width="110px" align="center"/>
      <el-table-column label="封面" align="center">
        <template v-slot="scope">
          <div class="image-container">
            <el-image
                style="height: 100px"
                :src="'/QQ/file/'+scope.row.cover"
                fit="contain"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="类型" width="70px" align="center" sortable/>
      <el-table-column  label="简介" width="60px" align="center">
        <template v-slot="scope">
          <el-popover
              placement="top-start"
              title="简介"
              :width="200"
              trigger="hover"
              :content=scope.row.description
          >
            <template #reference>
              <el-button class="m-2">...</el-button>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column  label="是否通过" width="80px" align="center">
      <template v-slot="scope">
        <el-checkbox v-model="scope.row.ifopen" :true-label="'true'" ></el-checkbox>
        <span>{{ scope.row.ifopen === 'true' ? '已通过' : '未通过' }}</span>
      </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="scope">
          <div style="display: flex; justify-content: center;">
            <el-button size="small" @click="getuser(scope.row)">修改</el-button>

            <el-popconfirm @confirm="inspect(scope.row)" title="是否进行该操作">
              <template #reference>
                <el-button  size="small" >{{ scope.row.ifopen === 'true' ? '撤销通过' : '通过' }}</el-button>
              </template>
            </el-popconfirm>
            <el-button size="small" @click="show(scope.row)">查看</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

  <el-pagination
      v-model:current-page="params.pageNum"
      v-model:page-size="params.pageSize"
      :page-sizes="[5, 10, 15, 20]"

      layout="total, sizes, prev, pager, next, jumper"
      :total=total
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
  />
<!--    查看章节数据-->
<el-dialog v-model="chapterDialog" title="章节内容">
  <el-table :data="chapterDialogData">
    <el-table-column prop="username" label="作者"/>
    <el-table-column  prop="title" label="小说名" />
    <el-table-column  prop="chapter" label="章节" />
    <el-table-column  prop="chaptername" label="章节名" />
    <el-table-column  label="是否通过" width="80px" align="center">
      <template v-slot="scope">
        <el-checkbox v-model="scope.row.isVisible" :true-label="'true'" ></el-checkbox>
        <span>{{ scope.row.isVisible === 'true' ? '已通过' : '未通过' }}</span>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      v-model:current-page="params.pageNum"
      v-model:page-size="params.pageSize"
      :page-sizes="[5, 10, 15, 20]"

      layout="total, sizes, prev, pager, next, jumper"
      :total=total
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
  />
</el-dialog>
<!--    修改封面数据-->
  <el-dialog v-model="dialogFormVisible" title="修改数据">
    <el-form :model="arrs">
      <el-form-item label="封面" :label-width="formLabelWidth">
        <el-image
            style="width: 100px; height: 100px"
            :src="'/QQ/file/'+arrs.cover"
            :preview-src-list="['/QQ/file/'+arrs.cover]"
        />

        <el-upload
            class="upload-demo"

            action="/QQ/file/imgupload"

            :on-success="handleUploadSuccess"
        >

          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div>
          </template>
        </el-upload>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click=update>
          修改
        </el-button>
      </span>
    </template>
<!--    添加数据-->
  </el-dialog>
  <el-dialog v-model="showDialog" title="添加数据">
    <el-form :model="arrs">
      <el-form-item label="用户名" :label-width="formLabelWidth">
        <el-input v-model="arrs.username" autocomplete="off" />
      </el-form-item>
      <el-form-item label="真实名" :label-width="formLabelWidth">
        <el-input v-model="arrs.fullname" autocomplete="off" />
      </el-form-item>
      <el-form-item label="权限" :label-width="formLabelWidth">
        <el-select v-model="arrs.permission" placeholder="Please select a zone">
          <el-option v-for="type in novletype" :key="type.id" :label=type.type :value=type.type />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  </div>

</template>


<script>
import request from "@/utils/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Read",
  data() {
    return {
      chapterDialog:false,

      params: {
        title: "",
        username: "",
        pageNum:1,
        pageSize:5,
      },
      dialogFormVisible:false,
      items:[],
      arrs:{},
      showDialog:false,
      total:0,
      novletype:{},
      chapterDialogData:[],
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
      formLabelWidth:"30%",

    }
  },

  methods:{
    findSearch(){

      request.get('/novel/get',{params:this.params}).then(res=>{

        if (res.code === '1') {
          this.items = res.data.list;
          this.total=res.data.total;
          request.get('/noveltype/get',{params:this.params}).then(res=>{

            if (res.code === '1') {
              this.novletype=res.data.list;


            } else {
              this.$message({
                message:res.msg,
                type: 'error'
              });
            }

          })

        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })

    },
    handleSizeChange(pageSize){
      this.params.pageSize=pageSize;
      this.findSearch()
    },
    handleCurrentChange(pageNum){
      this.params.pageNum=pageNum;
      this.findSearch()
    },
    getuser(item){
      this.arrs=item;
      this.dialogFormVisible=true;
    },

    handleUploadSuccess(res) {
      // 上传成功后，从服务器返回的数据中获取文件 URL
      this.arrs.cover=res.data;

    },
    update(){
      const novel=this.arrs;
      novel.username=null;
      novel.typeName=null;
      request.post('/novel/save',novel).then(res=>{
        if(res.code=='1'){

          this.$message({
            message: "修改成功",
            type: 'success'
          });

          this.arrs={}
          this.dialogFormVisible=false;
          this.findSearch();
        }else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    inspect(n){
      n.username=null
      n.typeName=null
      request.post('/novel/inspect',n).then(res=>{

        if (res.code === '1') {
          this.$message({
            message:"修改成功",
            type: 'success'
          });
          this.findSearch()

        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })
    },
    show(n){
      this.chapterDialog=true;
      n.pageNum=1
      n.pageSize=10
      const no=n

      request.get("/novelchapter/get",{params:no}).then(res=>{
        this.chapterDialogData=res.data.list
          }
      )

    }
  },
  mounted() {
    this.findSearch();


  }
}
</script>