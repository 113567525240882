<style scoped>

</style>


<template>
  <el-dialog v-model="showDialog" title="添加类型">
    <el-form :model="arrs">
      <el-form-item label="类型" label-width="30%">
        <el-input v-model="arrs.type" autocomplete="off" />
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-button @click="showDialog=true">添加小说类型</el-button>
  <el-table v-loading=loading :data="items" align="center">
    <el-table-column prop="type" label="分类" width="110px" align="center"/>
    <el-table-column label="操作" align="center">
      <template v-slot="scope">
        <div style="display: flex; justify-content: center;">
          <el-button size="small" @click="getuser(scope.row)">修改</el-button>

          <el-popconfirm @confirm="deleteItem(scope.row)" title="Are you sure to delete this?">
            <template #reference>
              <el-button id="delete" size="small">删除</el-button>
            </template>
          </el-popconfirm>

        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      v-model:current-page="params.pageNum"
      v-model:page-size="params.pageSize"
      :page-sizes="[5, 10, 15, 20]"

      layout="total, sizes, prev, pager, next, jumper"
      :total=total
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
  />

</template>

<script>
import request from "@/utils/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Noveltype",
  data(){
    return{
      items:[],
      params: {
        title: "",
        username: "",
        pageNum:1,
        pageSize:5,
      },
      loading:false,
      total:0,
      showDialog:false,
      arrs:{}
    }
  },
  methods:{
    findSearch(){

      request.get('/noveltype/get',{params:this.params}).then(res=>{

        if (res.code === '1') {
          this.items = res.data.list;
          this.total=res.data.total;


        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })

    },
    handleSizeChange(pageSize){
      this.params.pageSize=pageSize;
      this.findSearch()
    },
    handleCurrentChange(pageNum){
      this.params.pageNum=pageNum;
      this.findSearch()
    },
    submit(){
      request.post('/noveltype/save',this.arrs).then(res=>{

        if (res.code === '1') {
          this.showDialog=false;

          this.$message({
            message:'修改成功',
            type: 'success'
          });
          this.findSearch();
          this.arrs={}

        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })
    },
    getuser(item){
      this.arrs=item;
      this.showDialog=true;
    },
  },
  mounted() {
    this.findSearch();

  }
}
</script>