<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.container {
display:grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 270px));
  grid-gap: 20px;
align-items: center;


}

.item {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}
</style>

<template>
<el-button @click="showDialog=true">编写新小说</el-button>
  <div class="container" >

    <div v-for="item in items" :key="item.id" class="item" @click="navTo('/novelchapters?id='+item.id)" >

      <my-card :title=item.title :content=item.username :description=item.description :type=item.typeName :author=item.username  :image-src="'/QQ/file/'+item.cover" :novel="item.title" :value="value"></my-card>
    </div>

  </div>
  <el-dialog v-model="showDialog" title="小说基本内容">
    <el-form :model="arrs">
      <el-form-item label="小说名" :label-width="formLabelWidth">
        <el-input v-model="arrs.title" autocomplete="off" />
      </el-form-item>
      <el-form-item label="小说封面" :label-width="formLabelWidth">
        <el-upload
            class="avatar-uploader"
            action="/QQ/file/imgupload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
        >
          <el-image
              style="width: 100px; height: 100px"
              :src="'/QQ/file/'+arrs.cover"

          />

        </el-upload>
      </el-form-item>
      <el-form-item label="小说类型" :label-width="formLabelWidth">
        <el-select v-model="arrs.type"  placeholder="Please select a zone">

          <el-option v-for="type in novletype" :key="type.id" :label=type.type :value=type.id />

        </el-select>
      </el-form-item>
      <el-form-item label="小说简介" :label-width="formLabelWidth">
        <el-input v-model="arrs.description" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>


<script>
import request from "@/utils/request";

import MyCard from "@/components/组件/MyCard.vue";

export default {
// eslint-disable-next-line vue/multi-word-component-names
name:"Write",
  components: {MyCard},
data(){
  return{
    showDialog:false,
    imageUrl:"",
    arrs:{},
    user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
    params: {
      title: "",
      username: "",

    },
    items:[],
    total:0,
    formLabelWidth: '30%',
    novletype:{},
    value:5,
  }
},
  methods:{
    findSearch(){
      this.params.username=this.user.username;

      request.get('/novel/get',{params:this.params}).then(res=>{

        if (res.code === '1') {
          this.items = res.data.list;
          this.total=res.data.total;
          request.get('/noveltype/get',{params:this.params}).then(res=>{

            if (res.code === '1') {
              this.novletype=res.data.list;


            } else {
              this.$message({
                message:res.msg,
                type: 'error'
              });
            }

          })

        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })

    },
    handleAvatarSuccess(res) {
      // 上传成功后，从服务器返回的数据中获取文件 URL
      this.arrs.cover=res.data;

      // TODO: 将 URL 存储到数据库或其他地方
    },
    navTo(url){
      location.href=url;
    },
    submit(){
    let novellist=this.arrs
    this.arrs.uid=this.user.id;
      const exists = this.items.some(obj => obj.title === this.arrs.title);


      this.arrs.ifopen="false"

      if (!exists) {
      request.post('/novel/save',this.arrs).then(res=>{
        if(res.code=='1'){
          console.log(res.data)
          novellist.id=res.data;

          this.$message({
            message: "添加成功",
            type: 'success'
          });

          request.post("/file/novelupload",novellist).then(res=>{
            if(res.code=='1'){

              this.arrs={}
            }
          })

          this.showDialog=false;
          this.findSearch();

        }else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }


      })}else {
        this.$message({
          message: "小说以存在",
          type: 'error'
        });
      }


        this.showDialog=false;
this.arrs={}


    },


  },
  mounted() {

    this.params.username=this.user.username;
     this.findSearch();

  }
}
</script>