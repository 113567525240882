

<template>

    <div>
<div class="side">
  <div id="qrCode" ref="qrCodeDiv"></div>
</div>
    <div class="container">
      <div>
        <el-carousel  arrow="always" :interval="5999">
          <el-carousel-item v-for="item in carouselChartlist" :key="item.id">

            <el-image style="height: auto"
                    :src="'/QQ/file/'+item.img"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <el-tabs type="border-card">
        <el-tab-pane label="全部">

          <novel-card-list :novelList="items" :ratings="ratings" :noveltype="''" :isall="true" />

        </el-tab-pane>


<div v-for="t in typeList" :key="t.id" >

  <el-tab-pane :label="t.type">

    <novel-card-list :novelList="items" :ratings="ratings" :noveltype="t.type" :isall="false"/>

  </el-tab-pane>
</div>
     <el-tab-pane label="我的收藏">
       <novel-card-list :novelList="collectionList" :ratings="ratings" :noveltype="''" :isall="true"/>
     </el-tab-pane>
    </el-tabs>
  </div>
  </div>

</template>

<style scoped>
.container {

position: relative;
  left: 20%;
  padding: 0;
  width: 60%;
  height:100vh;
  background-color: #cccccc;
}

.item {
  margin-right: 40px;
}
.side{

  height: 20%;
  width: 10%;
 position: absolute;
  top: 40%;
  z-index: 99;
}
</style>
<script>
import request from "@/utils/request";

import CryptoJS from "crypto-js";
import router from "@/router";
import NovelCardList from "@/components/组件/NovelCard.vue";
import QRCode from 'qrcodejs2';


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'novels',
  components: {NovelCardList},

  data(){
    return{
      params: {
        title: "",
        username: "",
        pageNum:1,
        pageSize:10,
      },
      total:0,
      items:[],
      ratings:[],
      carouselChartlist:"",

      typeList:['悬疑类','言情类'],
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      collectionList:[]
    }
  },
  methods:{
    getnoverl(){


        request.get('/novel/get').then(res=>{

          if (res.code === '1') {
            this.items = res.data.list;
            this.total=res.data.total;
           request.post('rating/getAverageRatingForNovels',this.items).then(res=>{
          this.ratings=res.data;
           })

          } else {
            this.$message({
              message:res.msg,
              type: 'error'
            });
          }

        })

      },
    zhanshi(item){

      const data=JSON.stringify(item);
      const encryptedData = CryptoJS.AES.encrypt(data, 'secretKey').toString();
      router.push({
        name: 'novelchapters',
        query: { data:encryptedData}
      });
    },
    navTo(url){
      location.href=url;
    },
    getcarouselChart() {  // 分页查询

      request.get('/carouselChart/selectPage', {
        params: {

          router: "/novels",
        }
      }).then(res => {
        if (res.code === '1') {
          this.carouselChartlist = res.data.list

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getcollection(){
      request.get('/collection/selectPageByUserId/' + this.user.id).then(res => {
        if (res.code === "1") {
          this.collectionList = res.data
        }
      })
    },
    gettypeList() {
      request.get('/noveltype/get', {
        params: {

        }
      }).then(res => {
        if (res.code === '1') {
          this.typeList = res.data.list;
        }
        else {
          this.$message.error(res.msg)
        }
      })
    },
    bindQRCode: function () {
      const str = window.location.href;

      new QRCode(this.$refs.qrCodeDiv, {
        text: str,
        width: 100,
        height: 100,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })

    }

  },
  mounted() {
    this.getnoverl()
    this.getcarouselChart();
    this.getcollection();
    this.gettypeList();
    this.bindQRCode();

  }

}
</script>