<script>
import request from "@/utils/request";
import router from "@/router";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Qhome",
  data(){
    return{
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
    }
  },
  methods:{
    quit(){
      request.post("/onlineusers/delete",this.user)
      localStorage.removeItem('user');

      router.push('/')
    }
  }
}
</script>
<template>
  <div>

    <el-menu
        style="position: fixed; top: 0; left: 0; z-index: 60;right: 0"
index="1"
        :default-active="$route.path"
        class="el-menu-demo"
        mode="horizontal"
        :ellipsis="false"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        router
    >

      <el-menu-item index="/novels">阅读</el-menu-item>
      <el-menu-item index="/write">写小说</el-menu-item>
      <el-menu-item index="/message">聊天</el-menu-item>
      <el-menu-item index="/img" >图片查询</el-menu-item>
      <el-menu-item index="/read">后台管理</el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="/">注册</el-menu-item>
      <el-menu-item index="/1" v-if="user.username===''">登录</el-menu-item>
      <el-sub-menu v-if="user!=null" index="2">
        <template #title>{{user.username===''?'登录':user.username}}</template>
        <el-menu-item index="/info">个人信息</el-menu-item>

        <el-menu-item @click="quit" index="/">退出登录</el-menu-item>
      </el-sub-menu>

    </el-menu>

    <el-main style="padding-top: 52px;">
      <el-scrollbar >
      <router-view></router-view>
      </el-scrollbar>
    </el-main>

  </div>

</template>

<style scoped>
.el-main {
  padding: 0;
  margin-top: 0;
}
.flex-grow {
  flex-grow: 1;
}
</style>