<template>
  <el-col :span="4" style="margin: 10px">

      <div class="card">
        <el-image :src="imageSrc"  style="width: 250px; height: 200px;" fit="fill"/>
        <div class="card__content">
          <p class="card__title">{{ title }}</p>
          <p class="card__description">作者: {{ author }}</p>
          <p class="card__description">类型: {{ type }}</p>
          <p class="card__description">评分: <el-rate
              v-model="ratingValue"
              disabled
              show-score
              text-color="#ff9900"
              score-template="{value} points"
              :size="'small'"
          /></p>
          <p class="card__description">作品评价: {{ description }}</p>
        </div>

      </div>
    <div style="margin-top: 10px;text-align: center;line-height: 1;">
    <el-text style="font-size: 14px;color: #777;">{{novel}}</el-text>
    </div>
  </el-col>
</template>

<script>
export default {
  data() {
    return {
      ratingValue: this.value
    }
  },
  props: {
    novel:{
      type:String,
      required:true
    },
    imageSrc: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      default: 3 // 设置默认值为3
    }

  }
}
</script>

<style scoped>

.card {
  position: relative;
  text-align: center;
  width: 250px;
  height: 200px;
  background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100% );
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}



.card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card__content {
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.card:hover .card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}

.card__title {
  margin: 0;
  font-size: 24px;
  color: #333;
  font-weight: 700;
}

.card__description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #777;
  line-height: 1.4;
}

.card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

</style>