<script>
export default {
  name: "NotFoundComponent",
  data() {
    return {
      count: 5
    }

  },
  methods: {
    //倒计时功能

    countDown() {

      let timer = setInterval(() => {
            if (this.count > 0) {

              this.count--;
            } else {
              clearInterval(timer);
              this.$router.push("/qhome");
            }
          }
          , 1000);
    }

  },
  mounted() {
    //倒计时5秒后跳转到首页
    this.countDown();

  }
}
</script>

<template>
  <div>
    <h1>404 Not Found</h1>

    <p>Sorry, the page you requested could not be found.</p>
    <p>{{count}}秒后自动跳转到首页</p>
  </div>

</template>

<style scoped>

</style>