
<template>


 请扫描二维码或点击按钮获取授权,<br>
  <div id="qrCode" ref="qrCodeDiv" class="qrCode"></div>
  <div v-if="timeout==false">二维码失效时间为{{time}}秒</div>
  <div v-if="scanStatus">已扫描,请确认授权</div>
  <div v-if="authorizationStatus">授权成功</div>
  <div v-if="timeout">二维码已过期，请<a href="javascript:;" @click="getcode()" >重新获取</a></div>


</template>

<style scoped>
.qrCode {
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  margin-right: 10px;
}

</style>
<script>


import request from "@/utils/request";
import QRCode from "qrcodejs2";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Gpt",

  data() {
    return {
      time: 10,
      code: "123",
      web: null,
      scanStatus:false,
      authorizationStatus:false,
      user:{},
      timeout:false,
      qrcode:null,

    }
  },
  methods: {
    getcode() {
      this.timeout = false;
      if(this.qrcode){
        let qrCodeDiv = this.$refs.qrCodeDiv;

        // 清空 div 元素的所有子元素
        while (qrCodeDiv.firstChild) {
          qrCodeDiv.removeChild(qrCodeDiv.firstChild);
        }
      }

      request.get("/code/get").then(res => {
        this.code = res.data
        setTimeout(() => {
          const url = "ws://localhost:8090/codesocket/" + this.code;
          console.log(url)
          var socket = new WebSocket(url)


          this.web = socket;
          this.web.onopen = function () {
            console.log("连接成功");


          };


          this.web.onmessage = (event) => {
            console.log(event.data);

          };
          this.web.onerror = function () {

          }

          this.web.onclose = function () {
            console.log("WebSocket Connection Closed");
          };// 延迟一段时间后执行getSocket
        }, 1000); // 延迟1000毫秒（1秒）
        this.bindQRCode()
            // 每隔5秒执行一次g方法
        this.g()
      }

      )
    },
    getScoket() {
      request.post("/code/change/" + this.code)

    },
    bindQRCode: function () {
      const str = this.code;

        this.qrcode=new QRCode(this.$refs.qrCodeDiv, {
        text: str,
        width: 100,
        height: 100,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })

    },
   g(){

     let timer = setInterval(() => {

       request.post("/code/"+this.code).then(res => {

         if(res.data){
         if(res.data.scanStatus === 'SCANNED'){
           console.log('已扫描')
           this.scanStatus=true;

          if(res.data.authorizationStatus==='AUTHORIZED') {
            this.authorizationStatus = true;
            this.scanStatus = false;

            const user = res.data.user;

            request.post("/user/login", user).then(res => {

              if (res.code == 1) {
                clearInterval(timer);
                this.$message({
                  message: '登录成功',
                  type: 'success'
                });


                localStorage.setItem("user", JSON.stringify(res.data));

                this.$router.push('/LayOut');

              }
            })
          }
          }
         }else {
           this.$message({
             message: res.msg,
             type: 'error'
           });

           this.scanStatus = false;
           this.authorizationStatus = false
           this.timeout = true;
           this.qrcode.clear();
           this.qrcode.makeCode("二维码已过期，请重新获取");



           clearInterval(timer);
         }

       })
     }, 5000);

   }
  },
  mounted() {
    // this.bindQRCode()
    this.getcode()
    // 使用setTimeout来延迟执行getSocket

    setInterval(this.g(), 5000);


  },
  //
  beforeUnmount() {
    this.web.close();
    console.log("关闭WebSocket连接")
    clearInterval(this.timer);
  }
}
</script>
