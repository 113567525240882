<template>
  <div style="max-width: 100%;position: fixed; bottom: 20px;right: 20%;">
    <!-- 使用v-for指令循环渲染每个问题和回答 -->
    <div v-for="(item, index) in chatHistory" :key="index" style="text-align: left">
      <div style="right: 0; text-align: right">{{ user.username }}:{{ item.question }}</div>
      <div id="flag" ></div>
      <div style="text-align: left"><pre>GPT:{{ item.displayedAnswer }}</pre></div>
    </div>

    <div style="position: relative ; z-index: 2;">

    <el-input :rows="1" type="textarea" v-model="newQuestion" style="width: 600px; resize: vertical;"></el-input>
    <el-button @click="submitQuestion">提问</el-button>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ChatGpt",
  data() {
    return {
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
      dialogVisible: false,
      color:'blue',
      chatHistory: [], // 定义一个数组chatHistory用于存储每个问题和回答
      newQuestion: '', // 定义一个新的响应式数据newQuestion，用于存储输入框中的新问题
      newmodel:'gpt-4-1106-preview',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'hk-fycwmv1000013286e8c9dc207d6269f90a3a37c27c1f4b22'
      },
      url: 'https://api.openai-hk.com/v1/chat/completions',
      gptresult: ''
    }
  },
  methods: {
    submitQuestion() {
      // 将新问题添加到chatHistory数组中
      this.chatHistory.push({
        question: this.newQuestion,
        answer: '', // 初始回答为空字符串
        displayedAnswer: '' // 初始显示的回答为空字符串
      });

      // 准备发送给OpenAI的数据对象
      const requestData = {
        max_tokens: 1200,
        model: this.newmodel,
        temperature: 0.8,
        top_p: 1,
        presence_penalty: 1,
        messages: [
          {
            "role": "system",
            "content": "我想要你每一行输出的字最多为50个，超过50个的另起一行"
          },
          {
            role: 'user',
            content: this.newQuestion,
          }
        ]
      };

      // 发送请求并将回答添加到chatHistory数组中
      axios.post(this.url, requestData, { headers: this.headers })
          .then(response => {
            const result = response.data;
            const lastItem = this.chatHistory[this.chatHistory.length - 1];
            const answer = result.choices[0].message.content;
            this.displayAnswerByCharacter(lastItem, answer, 0);

          })
          .catch(error => {
            console.error(error);
          });

      // 清空输入框中的内容
      this.newQuestion = '';
    },
    displayAnswerByCharacter(item, answer, index) {
      var div = document.getElementById('flag')
      div.innerHTML="";

      if (index < answer.length) {
        div.innerHTML= '正在回答中';
        item.displayedAnswer += answer[index];
        setTimeout(() => {
          this.displayAnswerByCharacter(item, answer, index + 1);
        }, 50); // 每个字符显示的间隔时间，可以根据需要调整

      }




    }
  }
};
</script>