<style scoped>

</style>

<template>
  <div style="width: 100%;display: flex" >
<div id="bie" style="width: 100%;height: 400px"></div>
    <div id="user" style="width: 100%;height: 400px"></div>

  </div>
</template>


<script>
import * as echarts from 'echarts';
import request from "@/utils/request";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",

  data(){
    return{
      typelist:"",
      sexlist:""
    }
  },
  methods:{
    inittype(){
      request.get("/novel/typesize").then(res=>{
        if(res.code=="1"){

          // 使用map方法遍历原始数组并调整对象结构
          this.typelist = res.data.map(item => {
            return {
              value: item.count,
              name: item.typeName
            };
          });
        }

          }
      )
    },
    initsex(){
      request.get("/user/getsex").then(res=>{
            if(res.code=="1"){

              // 使用map方法遍历原始数组并调整对象结构
              this.sexlist = res.data.map(item => {
                const sexName = item.sex ? item.sex : "未知";
                return {
                  value: item.count,
                  name: sexName
                };
              });
            }

          }
      )
    },

    initBie(){


      let chartDom = document.getElementById('bie');
      let myChart = echarts.init(chartDom, { handleMouseWheel: { passive: true } });
      let option;
      let typeList=this.typelist

      option = {
        title: {
          text: '小说统计',
          subtext: '统计纬度:图书种类',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '小说分类',
            type: 'pie',
            radius: '50%',
            data:typeList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);

    },
    inituser(){


      const chartDom = document.getElementById('user');
      const myChart = echarts.init(chartDom, { handleMouseWheel: { passive: true } });
      let option;
      const sexList=this.sexlist

      option = {
        title: {
          text: '用户统计',
          subtext: '统计纬度:性别',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '性别',
            type: 'pie',
            radius: '50%',
            data:sexList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted() {


      this.inittype();
      this.initsex();

      // 设置延时，例如延时1秒后执行 initBie()
      setTimeout(() => {
        this.initBie();
        this.inituser();
      }, 500); // 1000毫秒 = 1秒





  }
}
</script>