export default {
    height:200,
    width:600,
    placeholder: '请输入内容',
    uploadFileName: 'file',
    uploadImgServer: '/QQ/file/wang/upload',
    menus: [
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色

        'justify',  // 对齐方式

        'emoticon',  // 表情
        'image',  // 插入图片
        'table',  // 表格


    ],
    colors : [
        "#1ede27",
        "#dc1212",
    ],
    emotions:[
        {
            "title": "表情",
            "type": "emoji",
            "content": [
                "😀",
                "😃",
                "😄",
                "😁",
                "😆",
                "😅",
                "😂",
                "🤣",
                "😊",
                "😇",
                "🙂",
                "🙃",
                "😉",
                "😌",
                "😍",
                "😘",
                "😗",
                "😙",
                "😚",
                "😋",
                "😛",
                "😝",
                "😜",
                "🤓",
                "😎",
                "😏",
                "😒",
                "😞",
                "😔",
                "😟",
                "😕",
                "🙁",
                "😣",
                "😖",
                "😫",
                "😩",
                "😢",
                "😭",
                "😤",
                "😠",
                "😡",
                "😳",
                "😱",
                "😨",
                "🤗",
                "🤔",
                "😶",
                "😑",
                "😬",
                "🙄",
                "😯",
                "😴",
                "😷",
                "🤑",
                "😈",
                "🤡",
                "💩",
                "👻",
                "💀",
                "👀",
                "👣"
            ]
        },
{
    "title": "手势",
    "type": "emoji",
    "content": [
    "👐",
    "🙌",
    "👏",
    "🤝",
    "👍",
    "👎",
    "👊",
    "✊",
    "🤛",
    "🤜",
    "🤞",
    "✌️",
    "🤘",
    "👌",
    "👈",
    "👉",
    "👆",
    "👇",
    "☝️",
    "✋",
    "🤚",
    "🖐",
    "🖖",
    "👋",
    "🤙",
    "💪",
    "🖕",
    "✍️",
    "🙏"
]
}
]
};
