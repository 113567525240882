<style>
.el-input{
  width: 200px;
}
</style>
<template>

  <div style="position: fixed;top: 60px;justify-content: center; align-items: center;width: 100%">
    <el-select v-model="img.type" placeholder="请选择类型">
      <el-option label="图片" value="tupian" />
      <el-option label="音频" value="audio" />
    </el-select>
   <el-input v-model="img.keyword"/>

    <el-button @click=submit>提交</el-button>
    <el-button @click="qingkong">清空</el-button>

  </div>
  <div class="image-box" style="position: fixed;top: 90px">

    <div class="image-container" style="height: 100%; overflow: auto;" >
<div v-loading=loading v-if="Type=='tupian'">
  <div style="clear: both;">
      <a v-for="item in arr" :key="item.img" :href="item.img" class="image-container" target="_blank">
      <div style="float: left; margin-right: 10px; width: 230px; height: 200px; overflow: hidden;">  <img :src="item.img" :alt="item.title"></div></a>
  </div>
</div>
      <div v-else-if="Type =='audio'">

        <a v-for="item in arr" :key="item.audio" :href="item.audio" target="_blank">
          {{item.title}}--------------{{ item.audio }}<br>
        </a>
      </div>
    </div>
  </div>

</template>

<script>
import request from "@/utils/request";
import {store} from "@/utils/store";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Img',

  data () {
    return {
      img:{

        keyword:"你好",

        type:"tupian"
      },
      loading: store.state.isLoading,
      Type:"",
      arr:{},

    }
  },
  methods:{
    submit(){
     this.Type=this.img.type;
     request.post("/img/get",this.img).then(res=>{
if(res!=null){
        if(res.code=="1"){

          this.arr=res.data;

        }else{
          this.$message({
            message:res.msg,
            type:"error"
          })
        }
      }
     else{
       this.$message({
     message:"网络错误，请重试",
      type:"error"
})}})
    },
    qingkong(){
      this.arr={}
    }
  },
  mounted() {
    this.submit();
    setTimeout(() => {
      this.loading = false; // 在3秒后停止加载状态
    }, 3000);
  }

}
</script>
<style>
.image-box{
  margin-top: 8px;
width: 100%;
  height: 100%;
  display: flex;
  margin-left: 20px;
}

.image-container img {
  /* 或者根据需要设置合适的宽度百分比 */
 width: 100%;
  height: 100%;
object-fit: cover;


}
</style>