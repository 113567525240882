<style scoped>
.container {
  display: flex;


  margin: 20px;
}

.novel-info {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #2c3e50;
  margin-left: 30px;
}



.novel-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.novel-info p {
  margin-bottom: 20px;
}

.rating {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.rating span {
  margin-right: 5px;
}
</style>


<template>
<div v-if="this.user.id==this.novel.uid">
  <el-button @click="addChapter">添加章节</el-button>
  <el-button @click="editChapter">编辑章节</el-button>
</div>
  <div class="container">

      <div class="novel-info">
        <el-image
            style="width:200px; height: 220px"
            :src="'/QQ/file/' + novel.cover"
        />

      </div>
    <table style="margin-left: 10px;text-align: left">
      <tr>
        <td>小说名:</td>
        <td>{{ novel.title }}</td>
      </tr>
      <tr>
        <td>作者:</td>
        <td>{{ novel.username }}</td>
      </tr>
      <tr>
        <td>类型:</td>
        <td>{{ novel.type }}</td>
      </tr>
      <tr>
        <td>简介:</td>
        <td>{{ novel.description }}</td>
      </tr>
      <tr>
        <td>评分:</td>
        <td>
          <el-rate v-model="value" disabled show-score text-color="#ff9900" score-template="{value} points"></el-rate>
        </td>
      </tr>
      <tr>
        <td>点赞数：{{novel.thumbsUp}}</td>
        <td>收藏数：{{novel.collection}}</td>
        <td>
          <el-popover :visible="visible" placement="top" :width="160">
            <div style="text-align: center; margin: 0">
              <el-rate v-model="value1" />
              <el-button size="small" text @click="visible = false">取消</el-button>
              <el-button size="small" type="primary" @click="userRating">确认</el-button>
            </div>
            <template #reference>
              <el-button @click="visible = true">读者评分</el-button>

            </template>
          </el-popover>
          <el-button type="primary" @click="thumbUp">{{isthumbsUp?'取消点赞':'点赞'}}</el-button>
          <el-button type="primary" @click="collection">{{iscollection?'取消收藏':'收藏'}}</el-button>
        </td>

      </tr>
    </table>





  </div>
  <div class="chapter-list" style="text-align: left">
    <h3>章节列表：</h3>

    <ul>
      <div v-for="novelchapter in novelchapters" :key="novelchapter.chapter">
        <div v-if="novelchapter.isVisible=='true'||this.user.username==novel.username">

             <el-button @click="chakan(novelchapter)">第{{novelchapter.chapter}}章{{novelchapter.chaptername}}</el-button>

        </div>

      </div>

    </ul>
  </div>


  <el-dialog v-model="addDialog" title="添加数据">
    <div>

      <el-form :model="arrs">
        <el-form-item label="章节">
        第{{arrs.chapter}}章
        </el-form-item>
        <el-form-item label="章节标题">
          <el-input v-model="arrs.chapter_name" :rules="[{ required: true, message: '请输入章节标题', trigger: 'blur' }]"></el-input>
        </el-form-item>
        <el-form-item label="章节内容">

          <div id="editor" style="text-align: left"></div>
          <el-button type="primary"  :disabled="isTitleEmpty" @click="add">添加章节</el-button>
        </el-form-item>
      </el-form>

    </div>
  </el-dialog>
  <el-dialog v-model="editDialog" title="编辑数据">
<!--下拉框,展示章节列表,选择章节,章节标题,章节内容-->



         第 <el-select  v-model="arrs.chapter" placeholder="请选择章节" @change="selectChapterChange">
      <div v-for="item in novelchapters" :key="item.chapter">
            <el-option  v-if="item.nid==novelid" :label="item.chapter" :value="item.chapter"></el-option>
    </div>
          </el-select>章<br>
    <el-input v-model="arrs.chapter_name"></el-input>
    <div id="editor2" style="text-align: left"></div>
    <el-button type="primary"  :disabled="isTitleEmpty" @click="edit">编辑章节</el-button>







  </el-dialog>
</template>

<script>

import request from "@/utils/request";
import axios from "axios";
import router from "@/router";
import E from 'wangeditor'
import editorConfig from "@/utils/editorConfig";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Novelchapters",

  data(){
    let novelid=this.$route.query.id

    return{
      editor1: null,
      editor2: null,
      arrs:{},
      novelid:novelid,
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
      params: {
        title: "",
        username:"",
        uid:"",
        chapter:"",

      },
      chapters:{
        title: "",
        chapter:"",
        chaptername:"",
        isVisible:"false"
      },
      items:[],
      txt:"",
      addDialog:false,
      novel:"",
      value:4.2,
      novelchapters:[],
      visible:false,
      value1:0,
      isthumbsUp:false,
      iscollection:false,
      editDialog:false,
      selectChapter:"",
      isTitleEmpty:false,
      requestSent:false,
    }
  },
  methods:{
    initWangEditor(elementId, content, editorNumber) {
      let editor = editorNumber === 1 ? this.editor1 : this.editor2;

      if (editor) {
        // 如果编辑器已经存在，直接设置内容即可
        editor.txt.html(content);
        return;
      }

      this.$nextTick(() => {
        editor = new E('#' + elementId);
        Object.assign(editor.config, editorConfig); // 假设 editorConfig 是你导入的富文本编辑器配置
        editor.create();
        setTimeout(() => {
          editor.txt.html(content);
        });

        // 根据 editorNumber 存储编辑器实例
        if (editorNumber === 1) {
          this.editor1 = editor;
        } else if (editorNumber === 2) {
          this.editor2 = editor;
        }
      });
    },
    init(){
    request.post("novel/select/"+this.novelid).then(res=>{
       var item=res.data;
      this.arrs=item;
      this.novel=item;
      this.params.title=item.title;
      this.params.uid=item.uid;
      request.post("/rating/get",this.novel).then(res=>{
        if(res.code=="1"&res.data!=null) {
          this.value = res.data.rating
        }


      })


      // 使用传递的数据进行处理
      request.post('/novelchapter/select/' + this.novelid).then(res => {

        if (res.code === '1') {
          this.novelchapters=res.data
          request.get("/thumbsUp/selectPage", {
            params: {
              uid: this.user.id,
              likeableId: this.novel.id,
              type: "novel",

            }}).then(res => {
            if (res.code === "1") {
              if (res.data.list.length > 0) {
                this.isthumbsUp = true
              }
            }
          })
          request.get("/collection/selectPage", {
            params: {
              uid: this.user.id,
              nid: this.novel.id,


            }}).then(res => {
            if (res.code === "1") {
              if (res.data.list.length > 0) {
                this.iscollection = true
              }
            }
          })

        }
        else{
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    })





    },
    addChapter(){
      this.arrs={}
      this.addDialog=true;

      this.initWangEditor('editor','',1)//初始化富文本编辑器
      request.get('/novelchapter/get',{params:this.params}).then(res=>{

        if (res.code === '1') {
          this.items = res.data.list;

          if(this.items.length!=0) {
            const idArray = this.items.map(obj => obj.chapter);
            const maxchapter = Math.max(...idArray);
            this.arrs.chapter=maxchapter+1;
          }else{
            this.arrs.chapter=1;
          }
        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })

    },
    editChapter() {

      this.editDialog = true;

      this.initWangEditor("editor2",'',2)//初始化富文本编辑器
    },
    add(){
      this.chapters.uid=this.user.id

      this.chapters.chapter=this.arrs.chapter
      this.chapters.chaptername=this.arrs.chapter_name
      this.chapters.username=null
      this.chapters.title=null
      this.chapters.nid=this.novel.id
      this.arrs.content=this.editor1.txt.html()
      this.arrs.uid=this.user.id;
      this.arrs.id=this.novel.id;
      if (!this.requestSent) {
        this.requestSent = true; // 设置标志位，避免重复发送请求
        request.post('/novelchapter/save', this.chapters).then(res => {
          if (res.code == "1") {
            this.arrs.chapterid = res.data;
            this.init()
          }
        })
        request.post("/file/novelupload", this.arrs).then(res => {
          if (res.code == "1") {
            this.$message({
              message: "添加成功",
              type: 'success'
            });
            this.init();
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        })
        this.arrs = {};
        this.addDialog = false;
      }
    },
  edit(){
    this.chapters.uid=this.user.id

    this.chapters.chapter=this.arrs.chapter
    this.chapters.chaptername=this.arrs.chapter_name
    this.chapters.username=null
    this.chapters.title=null
    this.chapters.nid=this.novel.id
    this.arrs.content=this.editor2.txt.html()
    this.arrs.uid=this.user.id;
    this.arrs.id=this.novel.id;
    request.post('/novelchapter/update',this.chapters).then(res=>{
      if(res.code=="1") {
        this.arrs.chapterid=res.data;

      }
    })
    request.post("/file/novelupload",this.arrs).then(res=>{
      if(res.code=="1"){
        this.$message({
          message: "修改成功",
          type: 'success'
        });
        this.init();
      }else{
        this.$message({
          message: res.msg,
          type: 'error'
        });
      }
    })
    this.arrs={};
    this.editDialog=false;
  },
    selectChapterChange(val) {

      this.arrs.chapter_name=this.novelchapters.find(item=>item.chapter==val).chaptername;
      this.arrs.id=this.novelid;

      request.post("/file/novelupload/novelchapter",this.arrs).then(res=>{
        if(res.code=="1"){
          this.arrs.content=res.data;
          this.editor2.txt.html(res.data)
        }else{
          this.$message({
            message: res.msg,
            type: 'error'
          });

        }
      })
    },
    chakan(novelchapter){

      novelchapter.chapter_name=novelchapter.chaptername
      novelchapter.id=this.novelid
      request.post("/file/novelupload/novelchapter",novelchapter).then(res=>{

        localStorage.setItem('chaptercontent', JSON.stringify(res.data));
        localStorage.setItem('chapterId', novelchapter.id);
        router.push("/novelcontent")

      })

    },
  thumbUp() {

    request.get("/thumbsUp/selectPage", {
      params: {
        uid: this.user.id,
        likeableId: this.novel.id,
        type: "novel",

      }
    }).then(res => {
      if (res.code === "1") {
        if (res.data.list.length > 0) {
          request.delete("/thumbsUp/delete/" + res.data.list[0].id

          ).then(res => {
                if (res.code === "1") {
                  this.$message.success('取消点赞成功')
                  this.isthumbsUp = false
                } else {
                  this.$message.error(res.msg)
                }
            this.init()
              }

          )
        }
        else {
          request.post("/thumbsUp/add", {
            uid: this.user.id,
            likeableId: this.novel.id,
            type: "novel"
          }
          ).then(res => {
            if (res.code === "1") {
              this.$message.success('点赞成功')
            }

            else {
              this.$message.error(res.msg)

            }
            this.init()
          })

        }

      } else {
        this.$message.error(res.msg)
      }
      this.init()
    }
    )

  },
    collection() {

    request.get("/collection/selectPage", {
      params: {
        uid: this.user.id,
        nid: this.novel.id,

      }
    }).then(res => {
      if (res.code === "1") {
        if (res.data.list.length > 0) {
          request.delete("/collection/delete/" + res.data.list[0].id


          ).then(res => {
            if (res.code === "1") {
              this.iscollection = false
              this.$message.success('取消收藏成功')
            } else {
              this.$message.error(res.msg)
            }
            this.init()
          }

          )
        }
        else {
          request.post("/collection/add", {
            uid: this.user.id,
            nid: this.novel.id
          }
          ).then(res => {
            if (res.code === "1") {
              this.$message.success('收藏成功')
            }
            else {
              this.$message.error(res.msg)
            }
            this.init()
          })



        }

      } else {
        this.$message.error(res.msg)
      }
      this.init()



    }
    )

  },

    l(){
      axios.get("https://api.vvhan.com/api/view?type=json")
    },
    userRating(){
      this.visible = false;
      const rating={
        nid:this.novel.id,
        uid:this.user.id,
        rating:this.value1,
      }
      request.post("rating/save",rating).then(res=>{
        if(res.code==="1"){
          this.$message.success('评分成功')
          this.init()
        }
        else{
          this.$message.error(res.msg)
        }
      })
    }

  },

  mounted() {

    this.init();

  }


}
</script>