<style scoped>

</style>

<template>
  <el-table :data="chapData">
    <el-table-column prop="username" label="作者"/>
    <el-table-column  prop="title" label="小说名" />
    <el-table-column  prop="chapter" label="章节" />
    <el-table-column  prop="chaptername" label="章节名" />
    <el-table-column  label="是否通过" width="80px" align="center">
      <template v-slot="scope">
        <el-checkbox v-model="scope.row.isVisible" :true-label="'true'" disabled></el-checkbox>
        <span>{{ scope.row.isVisible === 'true' ? '已通过' : '未通过' }}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template v-slot="scope">
        <div style="display: flex; justify-content: center;">


          <el-popconfirm @confirm="inspect(scope.row)" title="是否进行该操作">
            <template #reference>
              <el-button  size="small" >{{ scope.row.isVisible === 'true' ? '撤销通过' : '通过' }}</el-button>
            </template>
          </el-popconfirm>
          <el-button size="small" @click="chakan(scope.row)">查看</el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      v-model:current-page="params.pageNum"
      v-model:page-size="params.pageSize"
      :page-sizes="[5, 10, 15, 20]"

      layout="total, sizes, prev, pager, next, jumper"
      :total=total
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
  />
  <el-dialog v-model="txtDialog" style="text-align: left">
    <div v-html="txt"></div>
  </el-dialog>
</template>


<script>
import request from "@/utils/request";


export default {
  name: "ChapterTable",
  data(){
    return{
      chapData:[],
      txtDialog:false,
      params: {
        title: "",
        username:"",
        uid:"",
        chapter:"",
        pageNum:1,
        pageSize:5,
      },
      total:0,
      txt:""
    }
  },
  methods:{
    findSearch() {

      request.get('/novelchapter/get', {params: this.params}).then(res => {

        if (res.code === '1') {

          this.chapData=res.data.list
          this.total=res.data.total;
        }
      })
    },
    handleSizeChange(pageSize){
      this.params.pageSize=pageSize;
      this.findSearch()
    },
    handleCurrentChange(pageNum){
      this.params.pageNum=pageNum;
      this.findSearch()
    },
    chakan(novelchapter){


      this.txtDialog=true;
      novelchapter.chapter_name=novelchapter.chaptername;
      novelchapter.chapterid=novelchapter.id;
      novelchapter.id=novelchapter.nid;
      request.post("/file/novelupload/novelchapter",novelchapter).then(res=>{
        if (res.code === '1') {
          this.txt = res.data;
        }
        else {

            this.$message({
              message:res.msg,
              type: 'error'
            });
        }

      })

    },
    inspect(n){
      n.username=null;
      n.title=null;
      request.post('/novelchapter/inspect',n).then(res=>{

        if (res.code === '1') {
          this.$message({
            message:"修改成功",
            type: 'success'
          });
          this.findSearch()

        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })
    }
  },
  mounted() {
    this.findSearch()
  }
}
</script>